.main-header{
  width:100%;
  z-index:99;
  position: fixed;
  top: 0;
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  transition: all 0.4s ease;
}


.header-desktop__saperator{
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  margin-left: 124px;
  top: 100px;
  position: absolute;
  width: calc(100% - 100px);
  right: 358px;
}
.header-mobile .header-mobile__logo img{
  @include respond-to('small'){
    width:100px;
  }
}
.header-search{
  margin-top: 7px;
  margin-left: 15px;
  cursor: pointer;
}

.header-desktop{
  height: 160px;
  background-repeat-y: repeat;
  height: 195px;
  @include respond-to('medium'){
    display:none;
  }
}

.logo{
  position: absolute;
  right: 20px;
  top: 34px;
}
.main-navi{
  left: 0;
  position: absolute;
}


.main-navi{
  display: inline-block;
  & > ul{
    display: inline-block;
    list-style: none;
    margin: side-values(35px 20px 0 0);
    padding: 0;
    & > li{
      float: right;
      margin-#{$left}: 3px;
      position: relative;
      & > a{
        font-size: 20px;
        color: #144a97;
        padding: 3px 10px;
        display: block;
        border: 1px solid transparent;
        &:hover{
          color:red;
        }
        &:focus,
        &:active{
          text-decoration: none;
        }
        &.active{
          color:red;
        }
      }
      & > a.marked{
        color:red;
      }
    }
  }

  &_child{
    //display: none;
    overflow: hidden;
    max-height: 1px;
    transition: .3s;
    z-index: 100000;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  &_parent{
    position: relative;

    &:hover{
      .main-navi_child{
        //display: block;
        max-height: 100vh;
        box-shadow: 0 0 30px rgba(0,0,0,.08);

      }
      ul{
        z-index: 1;
        opacity: 1;
      }
    }
    ul{

      list-style-type: none;
      padding: 10px 0;
      min-width: 200px;
      text-align: center;
      background-color: #fff;

      z-index: -1;
      opacity: 0;
      transition: .3s;
      li{
        width: 100%;
        min-height: 40px;
        border-bottom: 1px solid #eee;
        transition: .2s;

        &:hover{
          background-color: #eee;
        }

        a{
          width: 100%;
          height: 100%;
          display: block;
          color: #777;
          font-size: 16px;
          text-decoration: none;
          padding: 5px 20px;

          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }

}




.wrap-lang{
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 35px;
  & > svg.fa-angle-down{
    position: absolute;
    top: 26%;
    #{$right}: 10px;
    pointer-events: none;
    color: #000;
  }
}

.select-lang{
  padding: 5px 33px 6px 10px;
  font-size: 16px;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 3px;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus{
    outline: none;
  }
  & > option{
    color: #000;
  }
}

.header-mobile{
  display:none;
  border-bottom: 1px black solid;
  background-color: white;
  height: 70px;
  @include respond-to('medium'){
    display:block;
  }
  &__logo{
    margin-#{$left}: 15px;
    margin-top: 10px;
    float: $left;
    & > img{
      width: 200px;
    }
  }
  &__tel{
    display: inline-block;
    margin-top: 17px;
    svg{
      color: #000;
      font-size: 32px;
    }
  }
}

.main-header.scrolling{
  .header-desktop{
    height: 80px;
  }
  .main-navi{
    & > ul{
      margin-top: 25px;
    }
  }
  .wrap-lang{
    margin-top: 25px;
  }
  .logo{
    top: 15px;
    right: -10px;
  }

}


.main-header.sb-slide.scrolling{
  margin-right:30px;
  @include respond-to('medium'){
    margin-right: 0px;
  }
  .header-desktop__shadow{
      height: 20px;
      width: calc(100% - 207px);
      left: 0;
      top: 75px;
      position: absolute;
      color: black;
  }

}


.main-header.sb-slide.scrolling{
  background-color: white;
  .header-desktop__saperator{
    display:none;
  }
  .main-navi{
    margin-left:30px;
  }

  .logo img{
    content: url(/assets/images/logo-decorative2.png);
    position: relative;
    top: -17px;
    right: -20px;
  }

}
