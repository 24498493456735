footer{
  width:100%;
  position: relative;
  background-color: white;
  z-index: 1;
  padding: 50px 0 20px;
  @include respond-to('large'){
    padding: 20px 0;
  }
}
.ftr-wrap-nav a{
  color:#777;
}
.ftr-wrap-nav a:hover{
  color:red;
}
.contact_place p{
  margin:0
}

.hr-footer-bottom{
  border: 0;
  border-top: 1px solid #bebebe;
  margin-top: 40px;
  margin-bottom: 20px;
}
.footer-ico{
  .img_place{
    display:inline-block;
    top:-50px;
    position:relative;
  }
  .contact_place{
      display:inline-block;
      top: -30px;
      position: relative;
      right: 10px;
      font-weight:bold;

  }
  .contact_place_1{
    color:red;
  }
  .contact_place_2{
    color:blue;
  }
}

.credit-info-txt{
  display: inline-block;
  width:100%;
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  @include respond-to('small'){
    margin-right: 0;
  }
  & > p{
    font-size: 16px;
    font-weight:bold;
    color: red;
    margin: 0;
    @include respond-to('extra-small'){
      font-size: 14px;
    }
  }
  .contact{
    color:#144a97;
  }
}

.wrap-social{
  position: relative;
  display: inline-block;
  margin-#{$right}: 25px;
  @include respond-to('large'){
    margin-#{$right}: 20px;
  }
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  & > a{
    & > svg{
      color: #777777;
      margin-#{$left}: 15px;
    }
    &:hover{
      & > .fa-twitter{
        color: $clr-twitter;
      }
      & > .fa-youtube{
        color: $clr-youtube;
      }
      & > .fa-facebook-f{
        color: $clr-facebook;
      }
    }
  }
}
