

/*  start copy from bob */
.partners {
  margin: 0 0px;
  & .slick-list {
    padding: 0 0px;
  }
  & .slick-next,
  & .slick-prev {
    width: 24px;
    height: 28px;
    left: auto;
    right: auto;
  }
  & .slick-next {
    left: -115px;
    @include respond-to('huge') {
      left: -45px;
    }
    @include respond-to('large') {
      left: 10px;
    }
    @include respond-to('extra-small') {
      left: -10px;
    }
    &::before {
      content: $arrow-left;
    }
  }
  & .slick-prev {
    right: -115px;
    @include respond-to('huge') {
      right: -45px;
    }
    @include respond-to('large') {
      right: 10px;
    }
    @include respond-to('extra-small') {
      right: -10px;
    }
    &::before {
      content: $arrow-right;
    }
  }
}
.partners-item__icon{
  text-align:center;
}

* {
  outline:none !important;
}

.slick-slide img {
    display: inline;
    height:384px;
    @include respond-to('small') {
      height: 250px;
    }
}

.gal{
  & .title{
    text-align:center;
    color:#ec1e30;
    font-size:44px;
  }
  & .sub-content-wrapper__txt{
    text-align: center;
      font-size: 18px;
      overflow: hidden;
      color: #777777;
      height: 55px;

  }
  & .sub-content-wrapper__title{
    text-align:center;
    & h2{
      font-size:24px;
      color:#3362a5;
      text-align:center;
      @include respond-to('small') {
        font-size: 18px;
      }
    }
  }
  & .red_read_more{
    color: #ec1e30;
    font-size: 18px;
    border: 3px solid red;
    padding-top: 5px;
    padding-bottom: 5px;
    display: table;
    margin: 0 auto;
    font-weight: bold;
    border-radius: 25px;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration:none;
  }
  & .sub-content-wrapper__title{
    color:#144a97;
    height:25px;
    font-size:24px;
    font-weight:bold;
    margin-bottom: 15px;
  }


}



/* end copy */
