.catalog-sorting{
  margin-bottom:35px;
  padding-bottom:30px;
  border-bottom:1px #e6e6e6 solid;
}
.catalog-sorting__title{
  font-size:30px;
  color:#144a97;
}
.catalog-sorting__select{
  line-height: 35px;
  font-size: 22px;
  text-align: center;
}
.catalog-sorting__txt{
  font-size: 30px;
  color: #144a97;

}
.sub-content-wrapper__item{
  margin-bottom:76px;
}

.mac-title{
  position: absolute;
  z-index: 1000;
  top: 284px;
  right: 200px;
  & .__title{
    color: #ec1e30;
    font-size: 50px;
    font-weight: bold;
    @include respond-to('small'){
        font-size:30px;
    }
  }
  @include respond-to('small'){
      top: 100px;
      text-align: center;
      width: 100%;
      text-shadow: 2px 2px 2px hsla(0,0%,100%,.5);
      right: 0;
  }

}



.catalog-sorting__select select{
  outline: none;
  border: 1px #e6e6e6 solid;
  cursor: pointer;
}

.sub-content-wrapper__img {
    height: 350px;
    & img{
      max-height: 100%;
      margin: 0px auto;
      display: table;
    }
}
