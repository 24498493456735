.v-item{
  margin-top:60px;
  border-bottom: 1px #e6e6e6 solid;
  /* padding-top: 60px; */
  padding-bottom: 60px;
}


.v-item__intr{
  font-size: 22px;
  font-weight: bold;
  color: red;
  margin-left:50px;
}

.tto{
  border-top: 1px solid #e6e6e6;
  padding-top: 30px;
}


.sim-part{
  padding-top:40px;
  padding-bottom:40px;
  & .__txt{
    text-align:center;
    font-size:50px;
    font-weight: lighter;
    color:#808080;
  }

}

.v-item__txt{
  /* border-bottom: 1px #e6e6e6 solid; */
  margin-bottom: 20px;
}
.sub-content-wrapper__img_item img{
  height:450px;
}

.v-item__btn{
  border: 2px solid #144a97;
  color: #144a97;
  padding: 5px;
  width: 137px;
  height: 43px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: none !important;
  line-height: 28px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  margin-top: 45px;
  @include respond-to('small'){
      margin-top:30px;
      display: block;
  }

}
