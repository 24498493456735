.homepage-box {
    position: relative;
    display: block;
    margin-bottom: 30px;

    &__image {
        overflow: hidden;

        img {
            transition: transform 200ms;
        }
    }

    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        h2 {
            position: relative;
            font-size: 30px;
            padding: 5px;
            margin: 0;
            z-index: 10;
            color: #000;
        }

        &::before {
            content: " ";
            background-color: rgba(255, 255, 255, 0.8);
            position: absolute;
            height: 100%;
            left: 0;
            right: 0;
            z-index: 0;
        }
    }
    @include on-event(false) {
        img {
            transform: scale(1.1);
        }
    }
}
