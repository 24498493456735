.content-page-header__bg{
  width:100%;
  height:100%;
}

.content-page-header__img{
  position: absolute;
  height: 100px;
  width: 100px;
  top: 0;
  width: 100%;
  height: 100%;
  left: 10;
  background-repeat: no-repeat;
  @include respond-to('small'){
      background-size: contain;
      margin-top: 40px;
  }
}
.mobile_white_mark{
  @include respond-to('small'){
    /* display:none; */
  }
}

#sb-site{
  & .content-page-header{
    @include respond-to('small'){
      height:200px;
    }
  }
}
