.wrap-content{
  padding: 20px 0;
}

.wrap-all-content-not-banner {
    margin-top: 140px;
}
.content-page-header{
    position: relative;
    width: 100%;
    @include respond-to('small'){
      /* height:initial; */
    }
    height: 501px;
    overflow: hidden;
}
.content-page-header .img{
    height: 100%;
    background-repeat: no-repeat;
}
.header-title h1{
  color: red;
  position: absolute;
  top: 260px;
  font-size: 50px;
  z-index:1000;
  margin-right:300px;
  font-weight: bold;
  @include respond-to('medium'){
    width:100%;
    text-align:center;
    margin-right:0px;
  }
}
.content-page{
    margin-top:66px;
}
.content-page-menu{
    margin-bottom:80px;
    padding-right:50px;
    input,textarea{
      border-right: none;
      border-left: none;
      outline: none;
      border-top: none;
    }
    textarea{
      resize: none;
      border-bottom:1px #e6e6e6 solid;
      border-top: 1px solid #e6e6e6;
    }
    @include respond-to('small'){
      padding-right:0px;
    }

}

.content-page-menu ul{
  list-style-type: none;
  padding:0;
  @include respond-to('small'){
    margin: 0px auto;
    padding-bottom: 30px;
  }
}
.newslatter-btn.contact{
  margin-top: 0px;
    display: block;
    width: 181px;
    height: 38px;
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    border: 0;
    background-color: #144a97;
    position: relative;
    top: -25px;
}

.content-page-menu ul li{
  line-height: 50px;
}
.content-page-sidelinks{
  width:300px;
}

.content-page-menu ul li a{
  color:#777777;
  text-decoration:none;
  border-bottom: 1px #e6e6e6 solid;
  width:100%;
  display: block;
  font-size:18px;

}

.content-page-menu ul li a:hover{
  color:red;
  border-bottom: 1px red solid;
}
.content-page-menu .title{
  font-size:24px;
  color:#436eac;
}

.content-page-content{
  border-left: 1px #e6e6e6 solid;
  min-height: 900px;
  @include respond-to('small'){
    min-height:20px;
  }
}
.mobile_white_mark{
  display:none;
}

.title{
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  color: #165683;
  margin: 15px 0 30px;
}

.hr-content{
  border-top: 1px solid #d9d9d9;
  margin: 0 0 20px;
}
.med-hide{
  @include respond-to('medium'){
    width:0%;
  }
}

.content-text{
  h2,h3{
    font-size: 30px;
    font-weight: 300;
    line-height: 1.2;
    color: #089ea8;
		margin: 20px 0 20px;
		@include respond-to('large'){
      font-size: 26px;
    }
    @include respond-to('extra-small'){
      font-size: 20px;
    }
  }
  p{
    font-size: 20px;
    font-weight: 300;
    color: #000;
		line-height: 1.2;
		@include respond-to('extra-small'){
			font-size: 16px;
			text-align: justify;
		}
  }
  iframe{
    @include respond-to('small'){
      text-align: center !important;
      margin: 15px auto !important;
      width: 100% !important;
      height: auto !important;
      float: none !important;
      display: block !important;
    }
  }
  img{
    @include respond-to('small'){
      float: none !important;
      text-align: center !important;
      width: auto !important;
      height: auto !important;
      margin: 15px auto !important;
    }
  }
}
