#order_form {
    background: #15294e;
    /* height: calc(100vh - 200px); */
    height:100vh;
    right: -400px;
    position: fixed;
    top: 265px;
    @include respond-to('small'){
      top: 145px;
    }
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 400px;
    z-index: 900;
}
.select-wrapper{
  width:321px;
  @include respond-to('small'){
    max-width:200px;
  }
}
.form-group{
  & input,textarea{
    @include respond-to('small'){
      max-width:200px;
    }
  }
}
#order_form{
  & h2{
    @include respond-to('small'){
      font-size: 24px !important;
    }
  }

  @include respond-to('small'){
     right: -260px;
     width:260px;
  }


}

.btn.btn-block.btn-form{
  @include respond-to('small'){
    max-width:200px;
  }
}

#order_form{
  & .form-group{
    
  }
}

#order_form > div#txt_title {
  color: #439cd6;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    width: 140px;
    left: -140px;
    text-align: center;
    color: #fff;
    background-color: red;
    padding: 20px 10px;
    @include respond-to('small'){
      padding:0;
    }
}
.toggleForm {
    cursor: pointer;
    height: 80px;
    position: absolute;
    left: -115px;
    top: 0px;
    width: 115px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px #666;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    @include respond-to('small'){
      height: 46px;
    }

}
#order_form > div#txt_top, #order_form div#txt_bottom {
    color: #fff;
    padding: 40px 5%;
    @include respond-to('small'){
        padding:0;
    }

    text-align: center;
}
#order_form.open {
    right: 0;
}
.toggleForm.close{
  opacity:1;
}

.btn.btn-block.btn-form {
    margin-top: 22px;
    display: block;
    width: 100%;
    height: 40px;
    font-size: 19px;
    color: #fff;
    border: 0;
    background-color: #144a97;
    position: relative;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
