.wrapper-banners{
  position: relative;
  width: 100%;
  /* height: 800px; */
  height:100vh;
  overflow: hidden;
  @include respond-to('huge'){
    /* height: 650px; */
    height:100vh;
  }
  @include respond-to('large'){
    /* height: 450px; */
    height:100vh;
  }
  @include respond-to('extra-small'){
    /* height: 300px; */
    height:100vh;
  }
}
a {
  outline:none !important;
}

.banner-item__link{
  margin-top:45px;
}

.banner_red_btn{
    display: inline-block;
    border: 1px solid red;
    color: red;
    height: 43px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    padding: 5px 10px;

    @include respond-to('small'){

      line-height: 15px;
      padding: 5px 9px;
      margin-top: 0px;

      font-size: 16px;
      height: 43px;
    }


}
.banner_red_btn:hover{
  text-decoration:none;
  color:red;
}

.banner-item__title{
  color:red;
  font-size:50px;
  font-weight:bold;
  @include respond-to('medium'){
    font-size:30px;
  }
}
.banner-item__content{
  color:#144a97;
  font-size:50px;
  height:3em;
  overflow:hidden;
  @include respond-to('medium'){
    font-size:25px;
  }

  p{
    margin-top:-20px;
    margin-bottom:-20px;
    @include respond-to('medium'){
        margin-top:0px;
        margin-bottom:0px;
    }

  }
}

.banner-item__textbox{
  top:340px;
  right:304px;
  position:absolute;
  z-index: 99999999999;
  @include respond-to('medium'){
    top: 90px;
    right: 60px;
  }
  & .mobile_white_mark{
    display:inline;
  }
  @include respond-to('small'){
    top: 50vh;
    margin: 0px auto;
    display: table;
    width: 100%;
    right: 0;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
  }


}

.wrap-banner-scroll{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('medium'){
    position: static;
  }
}

.banner-item{
  width: 100%;
  /* min-height: 800px; */
  height:100vh;

  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('huge'){
    /* min-height: 650px; */
    height:100vh;
  }
  @include respond-to('large'){
    /* min-height: 450px; */
    height:100vh;
  }
  @include respond-to('extra-small'){
    /* min-height: 300px; */
    height:100vh;
  }
}


.banner-item::before {
  content: " ";
  background-image: url(../images/bg.png);
  width: 100%;
  height: 100%;
  z-index: 20000;
  position: absolute;
  left: 0;
  top: 0;
}
