.content-page-contact{
    width:300px;
    height:460px;
    -webkit-box-shadow: -1px 1px 129px -54px rgba(0,0,0,.75);
    box-shadow: -1px 1px 129px -54px rgba(0,0,0,.75);
    position:relative;

    @include respond-to('small'){
      margin:0px auto;
    }


}

.content-page-contactform select{
    border: 0;
}

.content-page__your_service{
    background-image: url(/assets/images/your_service.png);
    width: 130px;
    height: 130px;
    position: absolute;
    left: -2px;
    top: -2px;
}
.content-page-contactform li,.content-page-contactform button{
  margin-right:50px;
}
.content-page-contactform ul{
  padding-top:50px;
}
.content-page-contact-all .title{
  @include respond-to('small'){
    text-align:center;
  }
}
