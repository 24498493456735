.wrapper-all-content{
  background-color:#fff;
  position: relative;
  width: 100%;
}
a{
  outline:none;
}

.btn-close-popup{
    position: absolute;
    top:-100px;
}



.homepage__buttons{
  padding: 20px 0;
}
.content-wrapper{
  background-repeat: no-repeat;
  top: -40px;
  position: relative;
  height: 890px;
}



#sb-site{
  & .content-page-header{
    & .img{
      @include respond-to('small'){
          background-size: cover;
          min-height:255px;
      }
    }
  }
}

.second-section{
  min-height: 185px;
  & .main-content-article{
    @include respond-to('small'){
      margin-top: 0px;
      & .content{
        margin-top:0px;
        font-size:16px;
      }
      & .title{
        font-size:25px;
        margin: 15px 0 0px;
      }
    }
  }
}

.main-content-article{
  margin-top: 166px;
  color:white;
  .title{
    color:white;
  }
  .content{
    margin-top:60px;
    font-size:24px;
      p{
        margin:0;
      }

  }
  a{
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    height: 43px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 45px;
    @include respond-to('small'){
      font-size:16px;
      margin-top:0px;
    }
  }
}

.more-promotion{
  width: 100%;
  height: 800px;
  -webkit-background-size: cover;
  background-size: cover;
  margin-top: 55px;
  @include respond-to('small'){
    background-size: cover;
    background-repeat: no-repeat;
    height: inherit;
  }
}

.shadow-line{
  width: 100%;
  height: 25px;
}
