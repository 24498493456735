@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/contact-box/contact-box.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/head_with_picture/head_with_picture.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/inner-banner/inner-banner.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/order-mac-stick/order-mac-stick.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/popup-search/popup-search.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/side-menu/side-menu.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/site-footer/site-footer-form/site-footer-form.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/site-footer/site-footer-links/site-footer-links.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/site-footer/site-footer.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/site-header/site-header.scss";
@import "D:/base_folder/mashkar.php/app/resources/blocks_shared/social-share/social-share-btns.scss";
@import "D:/base_folder/mashkar.php/app/resources/layout/base-layout/base-layout.scss";
@import "D:/base_folder/mashkar.php/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/article/article-item-box/article-item-box.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/article/article-item.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/catalog/cat-box/cat-box.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/catalog/catalog-item/catalog-item.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/catalog/catalog-page.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/catalog/product-box/product-box.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/contact-us/form-elements.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/content/content-page.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/gallery/gallery.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/homepage/homepage_m.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/homepage/homepage-box/homepage-box.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/homepage/homepage-machines/homepage-machines.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/homepage/homepage-slider/homepage-slider.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/homepage/homepage.scss";
@import "D:/base_folder/mashkar.php/app/resources/pages/search/search.scss";
